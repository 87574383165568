// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-context {
  padding: 20px;
  max-width: 1280px;
  margin: 0 auto;
}

.contact-context h1 {
  text-align: center;
  margin-bottom: 0.5rem;
  color: #e8c140;
  font-size: 2.5rem;
}

.contact-context > p {
  text-align: center;
  margin-bottom: 1rem;
  color: #717171;
}
@media (max-width: 480px) {
  .contact-context h1 {
     font-size: 1.5rem;
   }
   .contact-context > p {
     font-size: 0.9rem;
}
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/contactUs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE;KACG,iBAAiB;GACnB;GACA;KACE,iBAAiB;AACtB;AACA","sourcesContent":[".contact-context {\n  padding: 20px;\n  max-width: 1280px;\n  margin: 0 auto;\n}\n\n.contact-context h1 {\n  text-align: center;\n  margin-bottom: 0.5rem;\n  color: #e8c140;\n  font-size: 2.5rem;\n}\n\n.contact-context > p {\n  text-align: center;\n  margin-bottom: 1rem;\n  color: #717171;\n}\n@media (max-width: 480px) {\n  .contact-context h1 {\n     font-size: 1.5rem;\n   }\n   .contact-context > p {\n     font-size: 0.9rem;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
