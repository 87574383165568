import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FAQItem from '../components/FAQItem';
import { faqData } from '../assets/data/faqData';
import '../assets/styles/about.css';
import { Link } from 'react-router-dom';
import Partners from '../components/Partners';

const About = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const openQuestion = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
        <Header />
        <div className="container"> 
        <section>
          <div id="aboutus">
            <h1>About Us</h1>
            <p>
              Song & Stark tours is a reputable Tour agency operating in Rwanda offering private and tailor-made tours to tourists. We are registered and a member of <span>Rwanda Tours and Travel Association</span>. Our agency stands apart with well-designed itineraries, excellent customer services and adventures in the wild, you will carry unforgettable memories of Rwanda’s beauty and wildlife with you.
              <br />
              <br />
              <p>
              Our partnership with different hotels, restaurants, resorts and lodges gives us an edge in offering excellent accommodation bookings while on tour as we are given priority when it comes to booking the best hotel rooms.</p>
              <br />
              Our tour packages are Mid-range and Luxurious, based on your budget a tour package can be arranged. We boost in offering excellent customer services, ease in making bookings for your trip thus customer satisfaction is guaranteed. 
            </p>
            <br />
            <p>We are represented by <a href="https://www.safaribookings.com/p6857" target="_blank" rel="noopener noreferrer"><span>SafariBookings</span></a> </p>
          </div>
        </section>
        <div id="faq">

          <div className="content">
            <h2>FAQs</h2>
            {faqData.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                isOpen={expandedIndex === index}
                onClick={() => openQuestion(index)}
              />
            ))}               
            <div className="contact">
              <span>My question is not here.</span>
              <Link to="/contact" className="btn-yellow link">Contact us</Link>
            </div>
          </div>
        </div>
        <Partners />
      <div className="container-full">


      </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
