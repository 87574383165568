// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton-loader-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.skeleton-loader {
  flex: 1 1;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.skeleton-image {
  width: 100%;
  height: 150px;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin-bottom: 10px;
}

.skeleton-text {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-bottom: 10px;
}

.skeleton-text.short {
  width: 60%;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
} `, "",{"version":3,"sources":["webpack://./src/assets/styles/sectionLoader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,WAAW;AACb;;AAEA;EACE,SAAO;EACP,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,wCAAwC;AAC1C;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".skeleton-loader-container {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n  width: 100%;\n}\n\n.skeleton-loader {\n  flex: 1;\n  background-color: #f0f0f0;\n  border-radius: 10px;\n  padding: 20px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.skeleton-image {\n  width: 100%;\n  height: 150px;\n  background-color: #e0e0e0;\n  border-radius: 10px;\n  margin-bottom: 10px;\n}\n\n.skeleton-text {\n  width: 100%;\n  height: 20px;\n  background-color: #e0e0e0;\n  border-radius: 5px;\n  margin-bottom: 10px;\n}\n\n.skeleton-text.short {\n  width: 60%;\n}\n\n@keyframes loading {\n  0% {\n    background-position: 200% 0;\n  }\n  100% {\n    background-position: -200% 0;\n  }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
