import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../variables";

const ReferralTracker = () => {
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("r"); 

  useEffect(() => {
    console.log("ReferralTracker Mounted"); 

    if (referralCode) {
      console.log("Referral Code Detected:", referralCode); 
      
      axios.get(`${API_URL}/referrals/getAllReferrals`)
        .then(res => {
          const referral = res.data.find(r => r.code === referralCode);
          if (referral) {
            localStorage.setItem("referralId", referral.id);
          }
        });

      localStorage.setItem("referralCode", referralCode);
    }
  }, [referralCode]);

  return null;
};

export default ReferralTracker;


