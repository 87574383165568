import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import WhyChooseUs from "../components/WhyChooseUs";
import PopularTours from "../components/PopularTours";
import Activities from "../components/Activities";
import Banner from "../components/Banner";
import Destinations from "../components/Destinations";
import Articles from "../components/Articles";
import Partners from "../components/Partners";
import Footer from "../components/Footer";
import { FaWhatsapp } from "react-icons/fa";
import Reviews from '../components/Reviews';

const Home = () => {
  return (
    <>
    <div className="home-container"></div>
      <div className="container-full">
        <div className="header-container">
          <Header />
          <Hero />
        </div>
      </div>
      <div className="container">
      <WhyChooseUs />
        <div className="popular-tours-title">
          <h2>Popular tours</h2>
        </div>    
        <PopularTours />
        <Destinations />
        <Activities />
         <div className="container-full">
        <Banner />
        </div> 

        <Reviews />
        <Partners />
        <Articles />
      </div>
      
      <div className="container-full">
        <Footer />
      </div>
      <a
        href="https://wa.me/+250794422747" 
        className="whatsapp-icon"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp /> Whatsapp Us!
      </a>
    </>
  );
};

export default Home;
