import React, { useState, useEffect } from "react";
import "../assets/styles/style.css";
import { FaArrowRight } from "react-icons/fa";
import { API_URL } from '../variables';
import axios from "axios";
import SectionLoader from './SectionLoader';
import { useImage } from '../components/ImageContext';

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    subscription_name: '',
    subscription_email: ''
  });
  const { getImageUrl } = useImage();

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await axios.get(`${API_URL}/article/getAllArticles`);
      const latestArticles = response.data.slice(0, 3);
      setArticles(latestArticles);
    } catch (error) {
      console.error('Error fetching articles:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('subscription_name', formData.subscription_name);
      formDataToSend.append('subscription_email', formData.subscription_email);

      await axios.post(`${API_URL}/subscription/createSubscription`, formDataToSend);
      
      alert('Thank you for subscribing!');
      setFormData({
        subscription_name: '',
        subscription_email: ''
      });
    } catch (error) {
      console.error('Error creating subscription:', error);
      alert('Failed to subscribe. Please try again.');
    }
  };

  const handleArticleClick = async (articleId) => {
    try {
      await axios.get(`${API_URL}/article/getArticleById/${articleId}`);
      window.location.href = `/articles/${articleId}`;
    } catch (error) {
      console.error('Error fetching article details:', error);
    }
  };

  if (loading) {
    return <SectionLoader />;
  }

  return (
    <section id="articles" className="articles-section">
      <div className="container">
        <div className="articles-section-title">
          <h2>Articles</h2>
        </div>
        <div className="articles-list">
          {articles.map((article) => (
            <div 
              key={article.id} 
              className="article-card"
              onClick={() => handleArticleClick(article.article_id)}
            >
              <img
                src={getImageUrl(article.article_image)}
                alt={article.article_title}
                className="article-image"
              />
              <div className="article-info">
                <p className="article-date">
                  {new Date(article.createdAt).toLocaleDateString()} | By {article.article_author}
                </p>
                <h3 className="article-title">{article.article_title}</h3>
                <p className="article-excerpt">
                  {article.article_content.replace(/<[^>]+>/g, '').slice(0, 150)}...
                </p>
                <button className="read-more-button">
                  Read More
                  <FaArrowRight className="read-more-icon" />
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="articles-view-all-container">
          <a href="/articles" className="articles-view-all-button">
            View All Articles
          </a>
        </div>
        <div className="subscribe-section">
          <div>
            <h3>Subscribe To Our Newsletter</h3>
            <p>Stay updated with our latest articles and offers.</p>
          </div>
          <form className="subscribe-form" onSubmit={handleSubscribe}>
            <input 
              type="text"
              name="subscription_name"
              placeholder="Your Name" 
              required 
              value={formData.subscription_name}
              onChange={handleInputChange}
            />
            <input 
              type="email"
              name="subscription_email" 
              placeholder="Your Email Address" 
              required 
              value={formData.subscription_email}
              onChange={handleInputChange}
            />

            <button type="submit">SUBSCRIBE</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Articles;