import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "../assets/styles/tripSurvey.css";
import { API_URL } from "../variables";
import Loader from "./Loader";

const api_url = `${API_URL}/custom_reservations/createReservation`;
const activities_url = `${API_URL}/activity/getAllActivities`;

const TripSurvey = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    reservation_date: '',
    message: '',
    people: '',
    interests: [],
  });

  useEffect(() => {
    const fetchActivities = async () => {
      setLoading(true);
      try {
        const response = await axios.get(activities_url);
        setActivities(response.data);
      } catch (error) {
        toast.error("Failed to load activities. Please refresh the page.");
      } finally {
        setLoading(false);
      }
    };
    fetchActivities();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        interests: checked
          ? [...prev.interests, value]
          : prev.interests.filter(id => id !== value),
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (formData.interests.length === 0) {
      toast.error("Please select at least one activity");
      return;
    }

    try {
      const response = await axios.post(
        api_url,
        {
          reservation_first_name: formData.firstName,
          reservation_last_name: formData.lastName,
          reservation_email: formData.email,
          reservation_phone: formData.phone,
          reservation_date: formData.reservation_date,
          message: formData.message,
          people: formData.people,
          activity_ids: JSON.stringify(formData.interests),
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200 || response.status === 201 || response.status === 202) {
        toast.success(`Thank you! Your reservation request has been received. We will contact you soon via email (${formData.email}).`, {
          duration: 4000,
          position: 'top-center',
        });
        
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          reservation_date: '',
          message: '',
          people: '',
          interests: [],
        });
      }
    } catch (error) {
      toast.error("Failed to submit reservation. Please try again.", {
        duration: 4000,
        position: 'top-center',
      });
    }
  };

  return (
    <div className="trip-survey">
      <Toaster position="top-center" reverseOrder={false} />
      
      <div className="interests">
        <h2>Select Your Activities</h2>
        {loading ? (
          <Loader />
        ) : (
          <ul>
            {activities.map(({ activity_id, activity_name }) => (
              <li key={activity_id}>
                <input
                  type="checkbox"
                  id={activity_id}
                  name="interests"
                  value={activity_id}
                  checked={formData.interests.includes(activity_id)}
                  onChange={handleChange}
                />
                <label htmlFor={activity_id}>{activity_name}</label>
              </li>
            ))}
          </ul>
        )}
      </div>

      <form className="form-section" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="first-name">First Name</label>
          <input
            type="text"
            id="first-name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="last-name">Last Name</label>
          <input
            type="text"
            id="last-name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="reservation_date">Arrival Date</label>
          <input
            type="date"
            id="reservation_date"
            name="reservation_date"
            value={formData.reservation_date}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="people">Number of travelers</label>
          <input
            type="number"
            id="people"
            name="people"
            value={formData.people}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            placeholder="Your message to us..."
            className="message-textarea"
          />
        </div>
        <button type="submit" className="btn-yellow">Book Now</button>
      </form>
    </div>
  );
};

export default TripSurvey;
