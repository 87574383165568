import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import "../assets/styles/tours.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { API_URL } from "../variables";
import { FaMapMarkerAlt, FaCheck, FaTimes } from "react-icons/fa";
import { getToken } from '../authService';  
import { useImage } from "../components/ImageContext";
import Loader from "../components/Loader";
import formatPrice from "../components/PriceFormatter";

const api_url = `${API_URL}/package/getAllPackages`;

const Tours = () => {
  const { getImageUrl } = useImage();
  const [tours, setTours] = useState([]);
  const [filteredTours, setFilteredTours] = useState([]); 
  const [activities, setActivities] = useState([]);
  const [filters, setFilters] = useState({
    tour_type: [],
    country: [],
    duration: [],
    maxPrice: null,
    rating: null,
    activities: [],
    destinations: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [toursPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const token = getToken();
        const response = await axios.get(api_url, {
          headers: {
            'Authorization': `${token}`,  
            'Content-Type': 'application/json'
          },
        });

        const validTours = response.data.filter((tour) => 
          tour.package_title && tour.package_location && tour.package_price
        ).map((tour) => ({
          id: tour.package_id,
          title: tour.package_title,
          image: tour.package_image,
          summary: tour.package_summary,
          location: tour.package_location,
          country: tour.package_country,
          tour_type: tour.tour_type,
          price: tour.package_price,
          days: parseInt(tour.package_days),
          special: tour.package_special || "N/A",
          bestPriceGuarantee: tour.best_price_guarantee,
          freeCancelation: tour.free_cancelation,
          isPopular: tour.is_popular,
          destinationNames: tour.destinations ? 
            tour.destinations.map(dest => dest.destination_name) : ['Unknown Destination'],
          activities: tour.activities
        }));

        setTours(validTours);
        setFilteredTours(validTours);
      } catch (error) {
        console.error("Error fetching tour packages:", error.response ? error.response.data : error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTours();
  }, []);

  // Fetch activities
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axios.get(`${API_URL}/activity/getAllActivities`);
        setActivities(response.data);
      } catch (error) {
        console.error("Error fetching activities:", error.response ? error.response.data : error.message);
      }
    };

    fetchActivities();
  }, []);

  // Apply filters whenever filters state changes
  useEffect(() => {
    const applyFilters = () => {
      let filtered = [...tours];

      // Filter by type
      if (filters.tour_type.length > 0) {
        filtered = filtered.filter(tour => 
          Array.isArray(tour.tour_type) && 
          tour.tour_type.some(type => filters.tour_type.includes(type))
        );
      }

      // Filter by country
      if (filters.country.length > 0) {
        filtered = filtered.filter(tour => filters.country.includes(tour.country));
      }

      // Filter by duration
      if (filters.duration.length > 0) {
        filtered = filtered.filter(tour => 
          filters.duration.includes(Number(tour.days))
        );
      }

      // Filter by max price
      if (filters.maxPrice) {
        filtered = filtered.filter(tour => tour.price <= filters.maxPrice);
      }

      // Filter by activities
      if (filters.activities.length > 0) {
        filtered = filtered.filter(tour => {
          return tour.activities && filters.activities.every(activity => tour.activities.includes(activity));
        });
      }

      // Filter by destinations
      if (filters.destinations && filters.destinations.length > 0) {
        filtered = filtered.filter(tour => 
          tour.destinationNames.some(dest => filters.destinations.includes(dest))
        );
      }

      setFilteredTours(filtered);
    };

    applyFilters();
  }, [filters, tours]); 

  useEffect(() => {
    // Check if we arrived with a selected destination
    if (location.state?.selectedDestination) {
      setFilters(prev => ({
        ...prev,
        destinations: [location.state.selectedDestination]
      }));
    }
  }, [location]);

  const indexOfLastTour = currentPage * toursPerPage;
  const indexOfFirstTour = indexOfLastTour - toursPerPage;
  const currentTours = filteredTours.slice(indexOfFirstTour, indexOfLastTour);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Header />
      <section id="tours" className="tours-section">
        <div className="tours-h1">
          <h1>Explore all our tours</h1>
        </div>
        <div className="tour-container">
          <div className="filter-section">
            <h3>Filter Tours</h3>

            {/* Tour Type Filter */}
            <div className="filter-group">
              <h4>Tour Type</h4>
              {["Solo", "Family Tour", "Group Tour"].map((type) => (
                <div key={type}>
                  <input
                    type="checkbox"
                    id={`type-${type}`}
                    checked={filters.tour_type.includes(type)}
                    onChange={(e) => {
                      const newTypeFilters = e.target.checked
                        ? [...filters.tour_type, type]
                        : filters.tour_type.filter(item => item !== type);
                      setFilters({ ...filters, tour_type: newTypeFilters });
                    }}
                  />
                  <label htmlFor={`type-${type}`}>{type}</label>
                </div>
              ))}
              <hr />
            </div>

            {/* Destinations Filter */}
            <div className="filter-group">
              <h4>Destinations</h4>
              {[...new Set(tours.flatMap(tour => tour.destinationNames))].map((destination) => (
                <div key={destination}>
                  <input
                    type="checkbox"
                    id={`destination-${destination}`}
                    checked={filters.destinations.includes(destination)}
                    onChange={(e) => {
                      const newDestinationFilters = e.target.checked
                        ? [...(filters.destinations || []), destination]
                        : (filters.destinations || []).filter((item) => item !== destination);
                      setFilters({ ...filters, destinations: newDestinationFilters });
                    }}
                  />
                  <label htmlFor={`destination-${destination}`}>{destination}</label>
                </div>
              ))}
              <hr />
            </div>

            {/* Duration Filter */}
            <div className="filter-group">
              <h4>Duration</h4>
              {[...new Set(tours.map((tour) => Number(tour.days)))].map((days) => (
                <div key={days}>
                  <input
                    type="checkbox"
                    id={`duration-${days}`}
                    onChange={(e) => {
                      const newDurationFilters = e.target.checked
                        ? [...filters.duration, Number(days)]
                        : filters.duration.filter((item) => item !== Number(days));
                      setFilters({ ...filters, duration: newDurationFilters });
                    }}
                  />
                  <label htmlFor={`duration-${days}`}>{days} Days</label>
                </div>
              ))}
              <hr />
            </div>

            {/* Max Price Filter */}
            <div className="filter-group">
              <h4>Max Price</h4>
              <div className="input-text">
                <p>USD</p>
                <input
                  className="number-box"
                  type="number"
                  value={filters.maxPrice || ""}
                  onChange={(e) => setFilters({ ...filters, maxPrice: e.target.value })}
                />
                <p>or less</p>
              </div>
              <hr />
            </div>

            {/* Activities Filter */}
            <div className="filter-group">
              <h4>Activities</h4>
              {activities.map((activity) => (
                <div key={activity.activity_id}>
                  <input
                    type="checkbox"
                    id={`activity-${activity.activity_id}`}
                    onChange={(e) => {
                      const newActivityFilters = e.target.checked
                        ? [...filters.activities, activity.activity_name]
                        : filters.activities.filter((item) => item !== activity.activity_name);
                      setFilters({ ...filters, activities: newActivityFilters });
                    }}
                  />
                  <label htmlFor={`activity-${activity.activity_id}`}>
                    {activity.activity_name}
                  </label>
                </div>
              ))}
              <hr />
            </div>

            {/* Rating Filter */}
            <div className="filter-group">
              <h4>Rating</h4>
              {[4.5, 4, 3.5, 0].map((rating) => (
                <div key={rating}>
                  <input
                    type="radio"
                    id={`rating-${rating}`}
                    name="rating"
                    onChange={() => setFilters({ ...filters, rating })}
                  />
                  <label htmlFor={`rating-${rating}`}>Over {rating}</label>
                </div>
              ))}
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div className="tour-list">
              {currentTours.map((tour) => (
                <div key={tour.id} className="tour-card">
                  <div className="tour-image-container">
                    <img
                      src={getImageUrl(tour.image)}
                      alt={tour.title}
                      className="tour-image"
                    />
                    <p className="tour-destination">
                      <FaMapMarkerAlt style={{ marginRight: '5px' }} />
                      {tour.destinationNames.join(', ')}
                    </p>
                  </div>
                  <div className="tour-content">
                    <div className="tour-info">
                      <h3 className="tour-title">{tour.title}</h3>
                      <p className="tour-summary" dangerouslySetInnerHTML={{ __html: tour.summary }} />
                      <div className="tour-special">
                        <span className="special-item">
                          Best Price Guarantee: {tour.bestPriceGuarantee === 1 ? <FaCheck className="check-icon" /> : <FaTimes className="times-icon" />}
                        </span>
                        <span className="special-item">
                          Free Cancellation: {tour.freeCancelation === 1 ? <FaCheck className="check-icon" /> : <FaTimes className="times-icon" />}
                        </span>
                      </div>
                    </div>
                    <div className="tour-details-card">
                      <p className="tour-days">
                        {parseInt(tour.days)} Days {parseInt(tour.days) - 1} Nights
                      </p>
                      <p className="tour-price">{formatPrice(tour.price)}</p>
                      <Link to={`/tours/${tour.id}`}>
                        <button className="about-btn">Details</button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}

              {/* Pagination */}
              <div className="pagination">
                {Array.from({ length: Math.ceil(filteredTours.length / toursPerPage) }).map(
                  (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => paginate(index + 1)}
                      className={currentPage === index + 1 ? "active" : ""}
                    >
                      {index + 1}
                    </button>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Tours;
