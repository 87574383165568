import React, { useState } from 'react';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import "../assets/styles/surveyForm.css";
import { API_URL } from '../variables';
import { FaMapMarkerAlt } from 'react-icons/fa';
import formatPrice from './PriceFormatter';

const SurveyForm = ({ packageId, price, destination }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    people: '',
    date: '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const referralId = localStorage.getItem("referralId");
      
      await axios.post(
        `${API_URL}/reservations/createReservation`,
        {
          reservation_name: formData.name,
          reservation_email: formData.email,
          reservation_phone: formData.phone,
          reservation_message: formData.message,
          people: formData.people,
          package_id: packageId,
          reservation_date: formData.date,
          referral_id: referralId
        },
        {
          timeout: 30000,
        }
      );

      toast.success("Reservation submitted! We'll send confirmation details to your email.");
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
        people: '',
        date: '',
      });
    } catch (error) {
      toast.error('Unable to submit reservation. Please try again or contact support.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="info-section">
      <h3>Destination: 
        <span className="details-destination">
          <FaMapMarkerAlt/> 
          {Array.isArray(destination) ? destination.join(', ') : destination}
        </span>
      </h3>
      <h3>From: <span className="details-price">{formatPrice(price)}</span></h3>
      
      <form onSubmit={handleSubmit}>
        <input
          type="number"
          name="people"
          placeholder="Number of travelers"
          min="1"
          max="100"
          value={formData.people}
          onChange={handleChange}
          className="styled-input"
          required
          disabled={loading}
        />
        
        <input
          type="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          className="styled-datepicker-input"
          required
          disabled={loading}
        />
        
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
          disabled={loading}
        />
        
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
          disabled={loading}
        />
        
        <input
          type="tel"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
          required
          disabled={loading}
        />
        
        <textarea
          name="message"
          placeholder="Special Requirements..."
          value={formData.message}
          onChange={handleChange}
          rows="3"
          disabled={loading}
        />
        
        <button 
          type="submit" 
          className="btn-green"
          disabled={loading}
        >
          {loading ? 'Booking...' : 'Book Now'}
        </button>
      </form>
      
      <Toaster 
        position="top-right"
        toastOptions={{
          duration: 3000,
          style: {
            background: '#333',
            color: '#fff',
            padding: '12px',
          }
        }}
      />
    </div>
  );
};

export default SurveyForm;

