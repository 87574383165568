import React from "react";
import Header from "../components/Header";
import TripSurvey from "../components/TripSurvey";
import Footer from "../components/Footer";
import "../assets/styles/planTrip.css"; // 필요한 경우 CSS 경로 수정

const PlanTrip = () => {
  return (
    <>
      <Header />
      <div className="plan-trip">
        <h1>Plan your future trip!</h1>
        <TripSurvey />
      </div>
      <Footer />
    </>
  );
};

export default PlanTrip;