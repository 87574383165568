// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-trip {
    padding: 20px 4rem;
    max-width: 1280px;
    margin: 0 auto;
  }
  
  .plan-trip h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #E8C140;
    font-size:2.5rem;
  }  
  /* 작은 모바일: max-width 480px */
  @media (max-width: 480px) {
    .plan-trip h1 {
       font-size: 1.5rem;
     }
   .plan-trip {
    padding: 20px 1.5rem;
   }
  }

  `, "",{"version":3,"sources":["webpack://./src/assets/styles/planTrip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;EAClB;EACA,4BAA4B;EAC5B;IACE;OACG,iBAAiB;KACnB;GACF;IACC,oBAAoB;GACrB;EACD","sourcesContent":[".plan-trip {\n    padding: 20px 4rem;\n    max-width: 1280px;\n    margin: 0 auto;\n  }\n  \n  .plan-trip h1 {\n    text-align: center;\n    margin-bottom: 20px;\n    color: #E8C140;\n    font-size:2.5rem;\n  }  \n  /* 작은 모바일: max-width 480px */\n  @media (max-width: 480px) {\n    .plan-trip h1 {\n       font-size: 1.5rem;\n     }\n   .plan-trip {\n    padding: 20px 1.5rem;\n   }\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
