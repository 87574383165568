import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../assets/styles/tourDetails.css";
import Header from "../components/Header";
import PopularTours from "../components/PopularTours";
import Footer from "../components/Footer";
import SurveyForm from "../components/SurveyForm";
import axios from 'axios';
import { useImage } from "../components/ImageContext";
import { API_URL } from "../variables";
import Loader from "../components/Loader";
import formatPrice from "../components/PriceFormatter";
import { FaMapMarkerAlt, FaCalendar } from "react-icons/fa";

const api_url = `${API_URL}/package/getAllPackages`;

const TourDetails = () => {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [details, setDetails] = useState(null);
  const [itinerary, setItinerary] = useState([]);
  const [inclusions, setInclusions] = useState([]);
  const [exclusions, setExclusions] = useState([]);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openDay, setOpenDay] = useState(0);
  const [activeTab, setActiveTab] = useState('overview');

  const { getImageUrl } = useImage();

  useEffect(() => {
    const fetchTourDetails = async () => {
      try {
        const response = await axios.get(api_url);
        const tours = response.data;
        const tour = tours.find(tour => tour.package_id === id);

        if (tour) {
          const mainImage = getImageUrl(tour.package_image);
          const tourImages = tour.package_images ? 
            tour.package_images.map(img => getImageUrl(img)) : [];

          setDetails({
            title: tour.package_title,
            summary: tour.package_summary,
            location: tour.package_location,
            price: tour.package_price,
            days: tour.package_days,
            nights: tour.package_nights,
            maxPeople: tour.package_maxpeople,
            minAge: tour.package_minage,
            activities: tour.activities,
            image: mainImage,
            destinationName: tour.destinations && tour.destinations[0] ? 
            tour.destinations[0].destination_name : 'Unknown Destination',
            rates: tour.rates,
            rate_dates: tour.rate_dates,
            destinations: tour.destinations ? 
              tour.destinations.map(dest => dest.destination_name) : []
          });
          setItinerary(tour.package_itinerary || []);
          setInclusions(tour.package_inclusions || []);
          setExclusions(tour.package_exclusions || []);
          setImages([mainImage, ...tourImages]);
          setSelectedImage(mainImage);
        } else {
          setError('Tour not found.');
        }
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load tour details.');
        setIsLoading(false);
      }
    };

    fetchTourDetails();
  }, [id, getImageUrl]);

  const toggleDay = (idx) => {
    setOpenDay(openDay === idx ? null : idx);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <>
      <Header />
      <div className="tour-details">
        {/* Hero Section */}
        <div className="tour-hero">
          {/* Main Image */}
          <div className="main-image-container">
            <img 
              src={selectedImage} 
              alt={details.title} 
              className="tour-hero-image" 
            />
            
            <div className="tour-hero-content">
              <h1>{details.title}</h1>
              <div className="tour-meta">
                <div className="tour-meta-item">
                  <FaCalendar />
                  <span>{details.days} Days</span>
                </div>
                <div className="tour-meta-item locations">
                  <FaMapMarkerAlt className="attraction-icon" />
                  <div className="location-list">
                    {Array.isArray(details.location) 
                      ? details.location.map((loc, index) => (
                          <span key={index} className="location-tag">{loc}</span>
                        ))
                      : <span className="location-tag">{details.location}</span>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Vertical Thumbnails */}
          <div className="thumbnail-gallery-vertical">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Tour view ${index + 1}`}
                className={`thumbnail-image ${selectedImage === image ? 'selected' : ''}`}
                onClick={() => handleImageClick(image)}
              />
            ))}
          </div>
        </div>

        {/* Navigation */}
        <div className="tour-nav">
          <div 
            className={`tour-nav-item ${activeTab === 'overview' ? 'active' : ''}`}
            onClick={() => setActiveTab('overview')}
          >
            Overview
          </div>
          <div 
            className={`tour-nav-item ${activeTab === 'itinerary' ? 'active' : ''}`}
            onClick={() => setActiveTab('itinerary')}
          >
            Itinerary
          </div>
          <div 
            className={`tour-nav-item ${activeTab === 'includes' ? 'active' : ''}`}
            onClick={() => setActiveTab('includes')}
          >
            Inclusions
          </div>
          <div 
            className={`tour-nav-item ${activeTab === 'rates' ? 'active' : ''}`}
            onClick={() => setActiveTab('rates')}
          >
            Rates
          </div>
        </div>

        {/* Main Content */}
        <div className="tour-details-content">
          <div className="tour-main">
            {activeTab === 'overview' && (
              <div className="tour-overview">
                <h2>Tour Overview</h2>
                <p dangerouslySetInnerHTML={{ __html: details.summary }} />
                  {details.activities && (
                    <div className="activities">
                    <h3 className="activities-title">Activities</h3>
                    <ul className="activities-list">
                      {details.activities.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                  </ul>
                  </div>
                )}
              </div>
            )}
            
            {activeTab === 'itinerary' && (
              <div className="tour-itinerary">
                <h2>Itinerary</h2>
                <div className="itinerary-list">
                  {Array.isArray(itinerary) && itinerary.map((item, idx) => (
                    <div key={idx} className="itinerary-day">
                      <div className="day-header" onClick={() => toggleDay(idx)}>
                        <div className="day-title">
                          <span className="day-number">Day {idx + 1} :</span>
                          <h3>{item.title}</h3>
                        </div>
                        <button className="toggle-btn">
                          {openDay === idx ? '−' : '+'}
                        </button>
                      </div>
                      
                      {openDay === idx && (
                        <div className="day-content">
                          {item.image && (
                            <div className="day-image">
                              <img 
                                src={getImageUrl(item.image)} 
                                alt={`Day ${idx + 1} - ${item.title}`}
                                className="itinerary-image"
                              />
                            </div>
                          )}
                          <div 
                            className="timeline"
                            dangerouslySetInnerHTML={{ __html: item.content }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {activeTab === 'includes' && (
              <div className="includes-excludes">
                <div className="includes-grid">
                  <div className="inclusions">
                    <h2>Inclusions</h2>
                    <ul className="inclusion-list">
                      {inclusions.map((item, index) => (
                        <li key={index}>
                          <span className="icon-check">✓</span>
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="exclusions">
                    <h2>Exclusions</h2>
                    <ul className="exclusion-list">
                      {exclusions.map((item, index) => (
                        <li key={index}>
                          <span className="icon-cross">×</span>
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          

          {activeTab === 'rates' && (
            <div className="tour-rates">    
              <h2>Rates</h2>
              <p>Rates are subject to change based on the number of people and the season.</p>
              <div className="rate-table">
                <table key="rates-table" className="rate-table">
                  <thead>
                    <tr className="rate-header">
                      <th style={{ textAlign: 'left' }}>Dates</th>
                      {details.rates && details.rates.map((rate, index) => (
                        <th key={index}>{rate.title === 'solo' ? 'Solo' : `${rate.title} people`}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {details.rate_dates && details.rate_dates.map((dateRange, dateIndex) => (
                      <tr key={dateIndex}>
                        <td data-label="Dates">
                          {`${new Date(dateRange.start).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                          })} - ${new Date(dateRange.end).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                          })}`}
                        </td>
                        {details.rates && details.rates.map((rate, index) => (
                          <td key={index} data-label={rate.title === 'solo' ? 'Solo' : `${rate.title} people`}>
                            {rate.title === '7+' ? (
                              <button type="button" className="get-quote">Get Quote</button>
                            ) : (
                              formatPrice(rate.amount)
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}          
          </div>
          {/* Sidebar */}
          <div className="tour-sidebar">
            <div className="booking-card">
              <SurveyForm 
                packageId={id}
                price={details.price}
                destination={details.destinations}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h2 className="you-might-like">You might also like</h2>
      </div>
      <PopularTours />
      <Footer />
    </>
  );
};

export default TourDetails;
